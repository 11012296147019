import {
  cibDiscord,
  cibStripe
} from '@coreui/icons'

import {
  cilArrowRight,
  cilArrowLeft,
  cilFingerprint,
  cilBalanceScale,
  cilTransfer,
  cilDollar,
  cilLockLocked,
  cilUser,
  cilUserUnfollow,
  cilSpeedometer,
  cilWarning,
  cilMoon,
  cilSun
} from '@coreui/icons'

export const iconsSet = Object.assign({}, {
    cilArrowRight,
    cilArrowLeft,
    cilFingerprint,
    cilBalanceScale,
    cilTransfer,
    cilDollar,
    cilLockLocked,
    cilUser,
    cilUserUnfollow,
    cilSpeedometer,
    cilMoon,
    cilSun,
    cilWarning
  },
  {
    cibDiscord,
    cibStripe
  });
