const state = {
  token: null
};


function getJWT(state) {
  if (state.token) {
    let splits = state.token.split('.');
    if (splits.length < 2) {
      return null
    }
    return JSON.parse(atob(splits[1]))
  } else {
    return null
  }
}

// getters
const getters = {
  LoggedIn: state => state.token !== null && getJWT(state) !== null && getJWT(state).exp * 1000 > new Date().getTime(),
  Token: state => state.token,
  Username: state => state.token !== null && getJWT(state) !== null && getJWT(state).id
};

// actions
const actions = {
  setToken({commit}, data) {
    commit('setToken', data)
  },
  destroyToken({commit}) {
    commit('setToken', null)
  }
};

const mutations = {
  setToken(state, token) {
    state.token = token
  },

};

export default {
  state,
  getters,
  actions,
  mutations
}
